#KioskBoard-VirtualKeyboard {
  height: 35% !important;
}

.input_search {
  ::placeholder {
    padding-left: 10px;
  }
}

.hg-theme-default .hg-button {
  color: #000 !important;
}
